window.addEventListener('load', function () {
  AOS.init({
    once: true,
    delay: 200,
    duration: 700,
    easing: 'ease',

    disable: function () {
      var maxWidth = 800;
      return window.innerWidth < maxWidth;
    },
  });
});
